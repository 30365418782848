
import { defineComponent, reactive, toRefs, computed, h, ref } from 'vue'
import detailLayout from '@/components/detailLayout/index.vue'
import demandApi from '@/axios/api/demandApi'
import FormItem from '@/components/formItem/index.vue'
import editButton from '@/components/editButton/index.vue'
import { deleteModal } from '@/utils/deleteModal'
import { Achievement, AchievementDetail } from '@/model/industryManagement/achievement'
import AchieveApi from '@/axios/api/achievementApi'
import { useRoute } from 'vue-router'
import { message } from 'ant-design-vue'
import Data from '@/model/common/Data'
import { useStore } from 'vuex'
import yTable from '@/components/yTable/index.vue'
import productNumberSelect from '@/components/productNumberSelect/index.vue'
import { FormItemListType } from '@/types/comm'
interface TabItem {
  name: string
  key: string
}
export default defineComponent({
  components: { detailLayout, FormItem, editButton, yTable, productNumberSelect },
  setup() {
    const addFormRef = ref()
    //获取store
    const store = useStore()
    const buttonObj = store.getters.getButtonObj
    let route = useRoute()
    const pageData = reactive({
      formItemList: [
        {
          type: 'subInput',
          prop: 'demandNo',
          placeholder: '请输入需求编号',
          label: '需求编号',
          disabled: true,
        },
        {
          type: 'subInput',
          prop: 'finishDate',
          placeholder: '请输入成果创建时间',
          label: '成果创建时间',
          disabled: true,
        },
        {
          type: 'subInput',
          prop: 'quoteQty',
          placeholder: '请输入询价总数',
          label: '询价总数',
          disabled: true,
        },
        {
          type: 'subInput',
          prop: 'quoteTotalMoney',
          placeholder: '请输入询价金额',
          label: '询价金额',
          disabled: true,
        },
        {
          type: 'subInput',
          prop: 'quoteTimes',
          placeholder: '请输入询价次数',
          label: '询价次数',
          disabled: true,
        },
        {
          type: 'subInput',
          prop: 'quoteCustomerCount',
          placeholder: '请输入询价家数',
          label: '询价家数',
          disabled: true,
        },
        {
          type: 'subInput',
          prop: 'dealQty',
          placeholder: '请输入成交总数',
          label: '成交总数',
          disabled: true,
        },
        {
          type: 'subInput',
          prop: 'dealAmount',
          placeholder: '请输入成交金额',
          label: '成交金额',
          disabled: true,
        },
        {
          type: 'subInput',
          prop: 'dealCustomerCount',
          placeholder: '请输入成交家数',
          label: '成交家数',
          disabled: true,
        },
        {
          type: 'subInput',
          prop: 'dealTimes',
          placeholder: '请输入成交次数',
          label: '成交次数',
          disabled: true,
        },
      ] as FormItemListType<Achievement>,
      pNFormItemList: [
        {
          type: 'subInput',
          prop: 'productNumber',
          placeholder: '请输入临时型号',
          label: '临时型号',
          disabled: true,
        },
        {
          type: 'subInput',
          prop: 'officialProductNumber',
          placeholder: '请输入正式型号',
          label: '正式型号',
          disabled: true,
        },
        {
          type: 'subInput',
          prop: 'createdBy',
          placeholder: '请输入添加人',
          label: '添加人',
          disabled: true,
        },
        {
          type: 'subInput',
          prop: 'quoteQty',
          placeholder: '请输入询价总数',
          label: '询价总数',
          disabled: true,
        },
        {
          type: 'subInput',
          prop: 'quoteTotalMoney',
          placeholder: '请输入询价金额',
          label: '询价金额',
          disabled: true,
        },
        {
          type: 'subInput',
          prop: 'quoteTimes',
          placeholder: '请输入询价次数',
          label: '询价次数',
          disabled: true,
        },
        {
          type: 'subInput',
          prop: 'quoteCustomerCount',
          placeholder: '请输入询价家数',
          label: '询价家数',
          disabled: true,
        },
        {
          type: 'subInput',
          prop: 'dealQty',
          placeholder: '请输入成交总数',
          label: '成交总数',
          disabled: true,
        },
        {
          type: 'subInput',
          prop: 'dealAmount',
          placeholder: '请输入成交金额',
          label: '成交金额',
          disabled: true,
        },
        {
          type: 'subInput',
          prop: 'dealCustomerCount',
          placeholder: '请输入成交家数',
          label: '成交家数',
          disabled: true,
        },
        {
          type: 'subInput',
          prop: 'dealTimes',
          placeholder: '请输入成交次数',
          label: '成交次数',
          disabled: true,
        },
      ] as FormItemListType<Achievement>,
      demandDetail: new Achievement(),
      productNumberDetail: new Achievement(),
      tabList: [] as TabItem[],
      detailList: [] as Achievement[],
      reqForm: {
        currentPage: 1,
        pageSize: 10,
        dataCount: 0,
        data: {
          customerName: '',
          customerCode: '',
          primaryIndustry: '',
          secondaryIndustry: '',
          productNumber: '',
          quoteNo: '',
          companyName: '',
          salesman: '',
          pageType: 'askPrice',
        },
      },
      primaryIndustry: [],
      options: [] as Data[],
      dataSource: [] as AchievementDetail[],
      columnsObj: {
        askPrice: [
          {
            title: '序号',
            dataIndex: 'index',
            key: 'index',
            width: 80,
            customRender: ({ index }: { index: number }) => {
              return h('span', { style: 'color: #13c2c2;' }, index + 1)
            },
            resizable: true,
          },
          {
            title: '报价单号',
            dataIndex: 'askPriceNo',
            key: 'askPriceNo',
            width: 200,
            resizable: true,
          },
          {
            title: '客户名称',
            dataIndex: 'customerName',
            key: 'customerName',
            width: 150,
            resizable: true,
          },
          {
            title: '客户编码',
            dataIndex: 'customerCode',
            key: 'customerCode',
            width: 150,
            resizable: true,
          },

          {
            title: '所属行业',
            dataIndex: 'primaryIndustry',
            key: 'primaryIndustry',
            width: 130,
            resizable: true,
          },
          {
            title: '业务员',
            dataIndex: 'salesman',
            key: 'salesman',
            width: 110,
            resizable: true,
          },
          {
            title: '物料名称',
            dataIndex: 'materialsName',
            key: 'materialsName',
            width: 110,
            resizable: true,
          },
          {
            title: '含税单价',
            dataIndex: 'taxPrice',
            key: 'taxPrice',
            width: 100,
            resizable: true,
          },
          {
            title: '数量',
            dataIndex: 'quoteQty',
            key: 'quoteQty',
            width: 100,
            resizable: true,
          },
          {
            title: '含税总价',
            dataIndex: 'quoteTotalMoney',
            key: 'quoteTotalMoney',
            width: 100,
            resizable: true,
          },
        ],
        askPriceBK: [
          {
            title: '序号',
            dataIndex: 'index',
            key: 'index',
            width: 80,
            customRender: ({ index }: { index: number }) => {
              return h('span', { style: 'color: #13c2c2;' }, index + 1)
            },
            resizable: true,
          },
          {
            title: '报价单号',
            dataIndex: 'askPriceNo',
            key: 'askPriceNo',
            width: 200,
            resizable: true,
          },
          {
            title: '客户名称',
            dataIndex: 'customerName',
            key: 'customerName',
            width: 150,
            resizable: true,
          },
          {
            title: '客户编码',
            dataIndex: 'customerCode',
            key: 'customerCode',
            width: 150,
            resizable: true,
          },

          {
            title: '所属行业',
            dataIndex: 'primaryIndustry',
            key: 'primaryIndustry',
            width: 130,
            resizable: true,
          },
          {
            title: '业务员',
            dataIndex: 'salesman',
            key: 'salesman',
            width: 110,
            resizable: true,
          },
          {
            title: '物料名称',
            dataIndex: 'materialsName',
            key: 'materialsName',
            width: 110,
            resizable: true,
          },
          {
            title: '含税单价',
            dataIndex: 'taxPrice',
            key: 'taxPrice',
            width: 100,
            resizable: true,
          },
          {
            title: '数量',
            dataIndex: 'quoteQty',
            key: 'quoteQty',
            width: 100,
            resizable: true,
          },
          {
            title: '含税总价',
            dataIndex: 'quoteTotalMoney',
            key: 'quoteTotalMoney',
            width: 100,
            resizable: true,
          },
        ],
        order: [
          {
            title: '序号',
            dataIndex: 'index',
            key: 'index',
            width: 80,
            customRender: ({ index }: { index: number }) => {
              return h('span', { style: 'color: #13c2c2;' }, index + 1)
            },
            resizable: true,
          },
          {
            title: '订单号',
            dataIndex: 'askPriceNo',
            key: 'askPriceNo',
            width: 200,
            resizable: true,
          },
          {
            title: '客户名称',
            dataIndex: 'customerName',
            key: 'customerName',
            width: 150,
            resizable: true,
          },
          {
            title: '客户编码',
            dataIndex: 'customerCode',
            key: 'customerCode',
            width: 150,
            resizable: true,
          },

          {
            title: '所属行业',
            dataIndex: 'primaryIndustry',
            key: 'primaryIndustry',
            width: 130,
            resizable: true,
          },
          {
            title: '业务员',
            dataIndex: 'salesman',
            key: 'salesman',
            width: 110,
            resizable: true,
          },
          {
            title: '物料名称',
            dataIndex: 'materialsName',
            key: 'materialsName',
            width: 110,
            resizable: true,
          },
          {
            title: '含税单价',
            dataIndex: 'taxPrice',
            key: 'taxPrice',
            width: 100,
            resizable: true,
          },
          {
            title: '数量',
            dataIndex: 'quoteQty',
            key: 'quoteQty',
            width: 100,
            resizable: true,
          },
          {
            title: '含税总价',
            dataIndex: 'quoteTotalMoney',
            key: 'quoteTotalMoney',
            width: 100,
            resizable: true,
          },
        ],
      } as Data,
      selectedRowKeys: [],
      addForm: {
        demandNo: route.params.id,
        productNumber: undefined,
      },
      rules: {
        productNumber: [{ required: true, message: '产品型号不可为空', trigger: 'change' }],
        demandNo: [{ required: true, message: '需求编号不可为空', trigger: 'blur' }],
      },
      addVisible: false,
      pageTypeTabList: [
        {
          key: 'askPrice',
          title: '销售报价单',
        },
        {
          key: 'askPriceBK',
          title: '历史报价单',
        },
        {
          key: 'order',
          title: '订单',
        },
      ],
    })
    const handleOk = () => {
      addFormRef.value.validateFields().then(() => {
        demandApi
          .AddAchievementInfoVO(pageData.addForm)
          .then(() => {
            message.success('添加成果')
            pageData.addVisible = false
            getDetail()
          })
          .catch((err) => {
            message.error(err)
          })
      })
    }
    //从vuex中获取头部信息
    // pageData.demandDetail = store.state.resultsDetailForm
    //传入table的页面配置
    const currentConfigure = computed(() => {
      let page = {
        pageSize: pageData.reqForm.pageSize,
        currentPage: pageData.reqForm.currentPage,
        total: pageData.reqForm.dataCount,
        onChange(page: number, pageSize: number) {
          pageData.reqForm.pageSize = pageSize
          pageData.reqForm.currentPage = page
          initeList()
        },
        onShowSizeChange(current: number, size: number) {
          pageData.reqForm.pageSize = size
          pageData.reqForm.currentPage = current
          initeList()
        },
      }
      return page
    })
    //获取表格信息
    const initeList = () => {
      pageData.selectedRowKeys.length = 0
      pageData.reqForm.data.productNumber = (pageData.productNumberDetail.officialProductNumber ||
        pageData.productNumberDetail.productNumber) as string
      AchieveApi.ListDemandAchievementDetail(pageData.reqForm)
        .then((res) => {
          pageData.dataSource = []
          if (res.data) {
            Object.assign(pageData.dataSource, res.data)
          }
          pageData.reqForm.currentPage = res.currentPage as number
          pageData.reqForm.pageSize = res.pageSize as number
          pageData.reqForm.dataCount = res.dataCount as number
        })
        .catch((err) => {
          message.error(err)
        })
    }
    //重置数据
    const selectList = () => {
      pageData.reqForm.currentPage = 1
      pageData.reqForm.pageSize = 10
      initeList()
    }
    const getDetail = () => {
      demandApi
        .GetAchievementList({ demandNo: route.params.id })
        .then((res) => {
          pageData.demandDetail = res[0]
          let data = res.slice(1)
          pageData.detailList = data
          pageData.tabList =
            data?.map((item) => {
              return {
                key: item.productNumber as string,
                name: item.productNumber as string,
              }
            }) || []
        })
        .catch((err) => {
          message.error(err)
        })
    }
    getDetail()
    const tabChange = (key: string) => {
      pageData.productNumberDetail = pageData.detailList.find(
        (item) => item.productNumber === key
      ) as Achievement
      // pageData.reqForm.data.productNumber = key
      //查询数据
      selectList()
    }
    //行业改变事件
    const cascaderChange = (value: string[]) => {
      pageData.reqForm.data.primaryIndustry = value[0]
      pageData.reqForm.data.secondaryIndustry = value[1]
    }
    // 一级行业获取
    AchieveApi.queryOptionalType({ optionalType: 'primaryIndustry' })
      .then((res) => {
        if (res) {
          pageData.options = res.map((item) => {
            return {
              label: item.name,
              value: item.name,
              custom: item.id,
              isLeaf: false,
            }
          })
        }
      })
      .catch((err) => {
        message.error(err || '获取行业信息失败')
      })
    //获取二级行业
    const loadData = (val: Data[]) => {
      console.log(val)
      AchieveApi.queryOptionalType({
        optionalType: 'secondaryIndustry',
        subParameter: val[0].custom,
        noLoading: true,
      })
        .then((res) => {
          val[0].children = res.map((item) => {
            return {
              label: item.name,
              value: item.name,
              custom: item.id,
              isLeaf: true,
            }
          })
        })
        .catch((err) => {
          message.error(err)
        })
    }
    const addData = () => {
      console.log('增加')
    }
    const delData = () => {
      console.log('删除')
      if (
        pageData.tabList.length < 2 ||
        pageData.tabList[0]?.name === pageData.reqForm.data.productNumber
      ) {
        message.warning('不可删除')
        return
      }
      const ok = () => {
        demandApi
          .DelAchievementInfoVO({
            demandNo: route.params.id,
            productNumber: pageData.reqForm.data.productNumber,
          })
          .then(() => {
            message.success('删除成功')
            initeList()
            getDetail()
          })
          .catch((err) => {
            message.error(err)
          })
      }
      const onCancel = () => {
        console.log('Cancel')
      }
      deleteModal(ok, onCancel, '是否确定删除对应型号', '该型号一旦确定或将有无法恢复的风险')
    }
    // 表格类型改变事件
    const pageTypeTabChange = () => {
      selectList()
    }
    return {
      pageTypeTabChange,
      ...toRefs(pageData),
      labelCol: { style: { width: '100px' } },
      tabChange,
      addData,
      delData,
      buttonObj,
      cascaderChange,
      loadData,
      currentConfigure,
      selectList,
      addFormRef,
      handleOk,
    }
  },
})
