import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createSlots as _createSlots, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_spin = _resolveComponent("a-spin")!
  const _component_a_select = _resolveComponent("a-select")!

  return (_openBlock(), _createBlock(_component_a_select, {
    value: _ctx.value,
    placeholder: "输入进行模糊查询",
    "filter-option": false,
    "not-found-content": _ctx.fetching ? undefined : null,
    options: _ctx.data,
    onSearch: _ctx.fetchUser,
    "show-search": "",
    onChange: _ctx.change,
    onBlur: _ctx.blur,
    size: _ctx.size || 'default'
  }, _createSlots({ _: 2 }, [
    (_ctx.fetching)
      ? {
          name: "notFoundContent",
          fn: _withCtx(() => [
            _createVNode(_component_a_spin, { size: "small" })
          ]),
          key: "0"
        }
      : undefined
  ]), 1032, ["value", "not-found-content", "options", "onSearch", "onChange", "onBlur", "size"]))
}