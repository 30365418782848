import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-694234ae"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_table = _resolveComponent("a-table")!
  const _component_a_pagination = _resolveComponent("a-pagination")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_a_table, {
      dataSource: _ctx.dataSource,
      pagination: false,
      columns: _ctx.columns,
      "row-selection": {
      selectedRowKeys: _ctx.selectedRowKeys,
      onChange: _ctx.onSelectChange,
      fixed: 'left',
      align: 'left',
    },
      rowClassName: () => 'layoutTable',
      rowKey: _ctx.rowKey || 'id',
      ref: "peerTable",
      scroll: { x: 'max-content', scrollToFirstRowOnChange: true, y: 520 },
      onResizeColumn: _ctx.handleResizeColumn,
      onChange: _ctx.tableChange
    }, {
      bodyCell: _withCtx(({ column, text, record }) => [
        _renderSlot(_ctx.$slots, "bodyCell", {
          record: record,
          column: column,
          text: text
        }, undefined, true),
        (_ctx.statusList.includes(column.dataIndex))
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              _createTextVNode(_toDisplayString(column.statusObj[record[column.dataIndex]]), 1)
            ], 64))
          : _createCommentVNode("", true)
      ]),
      headerCell: _withCtx(({ column, text, record }) => [
        _renderSlot(_ctx.$slots, "headerCell", {
          record: record,
          column: column,
          text: text
        }, undefined, true)
      ]),
      _: 3
    }, 8, ["dataSource", "columns", "row-selection", "rowClassName", "rowKey", "onResizeColumn", "onChange"]),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", null, [
        _renderSlot(_ctx.$slots, "footerLeft", {}, undefined, true)
      ]),
      _createVNode(_component_a_pagination, {
        class: "tablePagination",
        current: _ctx.currentConfigure.currentPage,
        pageSize: _ctx.currentConfigure.pageSize,
        "show-size-changer": "",
        showQuickJumper: "",
        total: _ctx.currentConfigure.total,
        onShowSizeChange: _ctx.currentConfigure.onShowSizeChange,
        onChange: _ctx.currentConfigure.onChange,
        showTotal: _ctx.pagination.showTotal,
        pageSizeOptions: _ctx.pagination.pageSizeOptions
      }, null, 8, ["current", "pageSize", "total", "onShowSizeChange", "onChange", "showTotal", "pageSizeOptions"])
    ])
  ], 64))
}