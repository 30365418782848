// 请求类，分页参数
export class ReqPage {
  currentPage: number | undefined
  pageSize: number | undefined
  dataCount: number | undefined
  pageCount: number | undefined
  startCount: number | undefined
  endCount: number | undefined
  constructor() {
    this.currentPage = undefined
    this.pageSize = undefined
    this.dataCount = undefined
    this.pageCount = undefined
    this.startCount = undefined
    this.endCount = undefined
  }
}
