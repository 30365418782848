
import Data from '@/model/common/Data'
import { computed, defineComponent, reactive } from 'vue'
export default defineComponent({
  /*
  dataSouce 表格数据
  colums 表格列属性
  selectedRowKeys 选择的id
  rowKey 行的唯一值  selectedRowKeys的值
  currentConfigure  分页数据
  */
  props: ['dataSource', 'columns', 'selectedRowKeys', 'rowKey', 'currentConfigure'],
  emits: ['update:selectedRowKeys', 'update:selectedRow', 'sortChange'],
  setup(props, { emit }) {
    //选择改变时，获取选择的数据
    const onSelectChange = (changableRowKeys: string[], changableRow: Data[]) => {
      emit('update:selectedRowKeys', changableRowKeys)
      emit('update:selectedRow', changableRow)
    }
    //自定义表格列数据
    const statusList = computed(() => {
      return (
        props.columns?.filter((item: Data) => item.statusObj).map((item: Data) => item.dataIndex) ||
        []
      )
    })
    //分页配置
    const pagination = reactive({
      pageSizeOptions: ['10', '20', '30'],
      showTotal: function () {
        return `共计 ${props.currentConfigure.total || 0} 条记录`
      },
    })
    const handleResizeColumn = (w: number, col: Data) => {
      col.width = w
    }
    const tableChange = (_pagination: unknown, _filters: unknown, sorter: unknown) => {
      console.log(sorter)
      emit('sortChange', sorter)
    }
    return {
      handleResizeColumn,
      onSelectChange,
      pagination,
      statusList,
      tableChange,
    }
  },
})
